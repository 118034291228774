<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav nav-pills nav-fill"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("FILES.FILE") }}
          </span>

          <!-- Organization -->
          <base-input
            :label="`${$t('COMMON.ORGANIZATION')} (*)`"
            :placeholder="$t('COMMON.ORGANIZATION')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
          >
            <organization-selector
              :organization="file.organization.id"
              :filterable="true"
              :showAll="false"
              @organizationChanged="
                (organizationId) => {
                  file.organization.id = organizationId;
                  file.tags = [];
                }
              "
              :disabled="!!file.id || !!currentFolder"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />

          <div v-if="fileData" class="col-12 text-center display-4 mb-2">
            <a :href="fileData.url" target="_blank" class="col-12">
              {{ fileData.name }}
            </a>
          </div>

          <dropzone-file-upload
            :key="renderKey"
            v-model="fileModel"
            :url="uploadUrl"
            @change="fileModelChanged"
            @addedfile="
              () => {
                loading = true;
              }
            "
            @queuecomplete="
              () => {
                loading = false;
              }
            "
          >
          </dropzone-file-upload>

          <base-input
            v-model="file.file_name"
            :label="`${$t('COMMON.NAME')}`"
            :placeholder="`${$t('COMMON.NAME')}`"
          >
          </base-input>

          <!-- <base-input
            :label="`${$t('COMMON.TAGS')}`"
            :placeholder="`${$t('COMMON.TAGS')}`"
          >
            <tags-selector
              :tags="file.tags"
              :organization="file.organization.id"
              :disabled="!file.organization.id"
              @tagsChanged="
                (tags) => {
                  file.tags = tags;
                }
              "
            />
          </base-input> -->

          <!-- Excerpt -->
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="file.excerpt"> </html-editor>
          </base-input>
        </tab-pane>

        <tab-pane title="global" id="2">
          <span slot="title">
            {{ $t("FILES.SHARE") }}
          </span>

          <!-- Locations -->
          <base-input
            :label="`${$t('COMMON.LOCATIONS')} (*)`"
            :placeholder="$t('COMMON.LOCATIONS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <locations-selector
              :locations="file.allowedLocations"
              :filterable="true"
              :showAll="false"
              :disabled="!file.organization.id"
              @locationsChanged="
                (locations) => {
                  file.allowedLocations = locations;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />

          <base-input
            :label="`${$t('COMMON.ROLES')}`"
            :placeholder="$t('COMMON.ROLES')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
          >
            <roles-selector
              :roles="file.roles"
              :filterable="true"
              :filterOrganization="file.organization.id"
              :disabled="!file.organization.id"
              @rolesChanged="
                (roles) => {
                  file.roles = roles;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.roles" />

          <base-input
            :label="`${$t('COMMON.USERS')}`"
            :placeholder="$t('COMMON.USERS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <users-selector
              :users="file.users"
              :filterable="true"
              :filterOrganization="file.organization.id"
              :disabled="!file.organization.id"
              @usersChanged="
                (users) => {
                  file.users = users;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.users" />
        </tab-pane>
      </tabs>
    </div>
    <template slot="footer">
      <button
        v-if="!fileData"
        type="submit"
        class="btn btn-primary"
        @click="addFile"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.ADD_FILE") }}
      </button>

      <button
        v-if="!!fileData"
        type="submit"
        class="btn btn-primary"
        @click="editFile"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.EDIT_FILE") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { Tabs, TabPane } from "@/components";
// import TagsSelector from "@/components/TagsSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import RolesSelector from "@/components/RolesSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import defaultFile from "../defaultFile";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    Tabs,
    TabPane,
    HtmlEditor,
    ValidationError,
    // TagsSelector,
    OrganizationSelector,
    LocationsSelector,
    LocationsSelector,
    RolesSelector,
    UsersSelector,
    DropzoneFileUpload,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: Object,
      default: null,
      description: "File Data",
    },
    currentFolder: {
      type: Object,
      default: null,
      description: "Current file",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/folders/${this.currentFolder.id}/files`;
    },
  },

  data() {
    const fileData = this.getFileData(
      this.fileData ? this.fileData : defaultFile
    );
    return {
      loading: false,
      file: fileData,
      renderKey: 0,
      fileModel: [],
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.file = this.getFileData(defaultFile);
      this.$emit("onCloseModal", refresh);
    },

    getFileData(fileData) {
      const data = cloneDeep(fileData);
      if (this.currentFolder) {
        data.organization.id = this.currentFolder.organization.id;

        if (
          this.currentFolder.allowedLocations.length != 0 &&
          fileData.allowedLocations.length === 0
        ) {
          data.allowedLocations = [
            ...this.currentFolder.allowedLocations.map((item) => ({
              type: "locations",
              id: item.id,
            })),
          ];
        }

        if (this.currentFolder.tags.length != 0 && fileData.tags.length === 0) {
          data.tags = [
            ...this.currentFolder.tags.map((item) => ({
              type: "tags",
              id: item.id,
            })),
          ];
        }

        if (
          this.currentFolder.roles.length != 0 &&
          fileData.roles.length === 0
        ) {
          data.roles = [
            ...this.currentFolder.roles.map((item) => ({
              type: "roles",
              id: item.id,
            })),
          ];
        }

        if (
          this.currentFolder.users.length != 0 &&
          fileData.users.length === 0
        ) {
          data.users = [
            ...this.currentFolder.users.map((item) => ({
              type: "users",
              id: item.id,
            })),
          ];
        }
      } else {
        data = this.$fillUserOrganizationData(data);
      }
      return data;
    },

    async addFile() {
      this.loading = true;
      const fileData = this.getFileData(this.file);

      fileData.folder = {
        type: "folders",
        id: this.currentFolder.id,
      };
      fileData.path = JSON.parse(this.fileModel[0].xhr.response).path;
      fileData.name = `${fileData.file_name}.${fileData.file_extension}`;

      const existingFile = this.currentFolder.files.find((item) => {
        return item.name.toLowerCase() === fileData.name.toLowerCase();
      });
      if (existingFile) {
        await swal.fire({
          title: this.$t(`FILES.FILE_EXISTS_WITH_SAME_NAME`),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch("files/add", fileData);
        this.$notify({
          type: "success",
          message: this.$t("FILES.FILE_ADDED"),
        });
        this.closeModal(true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async editFile() {
      this.loading = true;
      const fileData = this.getFileData(this.file);
      if (this.fileModel.length === 1) {
        fileData.path = JSON.parse(this.fileModel[0].xhr.response).path;
      }
      fileData.name = `${fileData.file_name}.${fileData.file_extension}`;

      const existingFile = this.currentFolder.files.find((item) => {
        return (
          item.name.toLowerCase() === fileData.name.toLowerCase() &&
          item.id !== fileData.id
        );
      });
      if (existingFile) {
        await swal.fire({
          title: this.$t(`FILES.FILE_EXISTS_WITH_SAME_NAME`),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch("files/update", fileData);
        this.$notify({
          type: "success",
          message: this.$t("FILES.FILE_UPDATED"),
        });
        this.closeModal(true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    fileModelChanged(files) {
      const file = files[0];
      const fileParts = file.name.split(".");
      this.file.file_extension = fileParts.pop();
      this.file.file_name = fileParts.join(".");
    },
  },

  watch: {
    fileData: {
      handler(fileData) {
        this.file = this.getFileData(fileData ? fileData : defaultFile);
      },
      deep: true,
    },

    currentFolder: {
      handler(currentFolder) {
        this.file = this.getFileData(defaultFile);
      },
      deep: true,
    },

    showModal(showModal) {
      this.renderKey = this.renderKey + 1;
    },
  },
};
</script>
