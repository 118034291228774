var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('div',[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.FOLDER"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.folder.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.folder.id || !!_vm.currentFolder.id},on:{"organizationChanged":function (organizationId) {
                _vm.folder.organization.id = organizationId;
                _vm.folder.tags = [];
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.NAME'))),"placeholder":("" + (_vm.$t('COMMON.NAME')))},model:{value:(_vm.folder.name),callback:function ($$v) {_vm.$set(_vm.folder, "name", $$v)},expression:"folder.name"}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{model:{value:(_vm.folder.excerpt),callback:function ($$v) {_vm.$set(_vm.folder, "excerpt", $$v)},expression:"folder.excerpt"}})],1)],1),_c('tab-pane',{attrs:{"title":"global","id":"2"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.SHARE"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.LOCATIONS')) + " (*)"),"placeholder":_vm.$t('COMMON.LOCATIONS')}},[_c('locations-selector',{attrs:{"locations":_vm.folder.allowedLocations,"filterable":true,"showAll":false,"disabled":!_vm.folder.organization.id},on:{"locationsChanged":function (locations) {
                _vm.folder.allowedLocations = locations;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.ROLES'))),"placeholder":_vm.$t('COMMON.ROLES')}},[_c('roles-selector',{attrs:{"roles":_vm.folder.roles,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"rolesChanged":function (roles) {
                _vm.folder.roles = roles;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.roles}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.USERS'))),"placeholder":_vm.$t('COMMON.USERS')}},[_c('users-selector',{attrs:{"users":_vm.folder.users,"filterable":true,"filterOrganization":_vm.folder.organization.id,"disabled":!_vm.folder.organization.id},on:{"usersChanged":function (users) {
                _vm.folder.users = users;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.users}})],1)],1)],1),_c('template',{slot:"footer"},[(!_vm.folderData)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.addFolder}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("FILES.ADD_FOLDER"))+" ")]):_vm._e(),(!!_vm.folderData)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.editFolder}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("FILES.EDIT_FOLDER"))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }