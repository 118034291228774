<template>
  <el-select
    v-model="usersModel"
    @change="usersChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.USERS')"
    :filterable="filterable"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getUsers"
    :loading="loading"
  >
    <el-option
      v-for="user in usersList"
      :key="user.id"
      :value="user.id"
      :label="`${user.firstname} ${user.lastname} - ${user.email}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "users-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    users: {
      type: Array,
      default: null,
      description: "Users array",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Filter only null organizations",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterResellerNull: {
      type: Boolean,
      default: null,
      description: "Filter only null resellers",
    },
  },

  data() {
    return {
      usersModel: this.users.map((item) => item.id),
      usersList: {},
    };
  },

  setup() {},

  created() {
    this.getUsers(null, this.users);
  },

  methods: {
    async getUsers(query = null, users = null) {
      try {
        this.loading = true;
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
            ...(users ? { ids: users.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: null,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.filterReseller,
            },
          };
        }
        if (this.filterResellerNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: null,
            },
          };
        }

        await this.$store.dispatch("users/list", params);
        const usersArr = await this.$store.getters["users/list"];
        this.usersList = {};
        usersArr.forEach((user) => {
          this.usersList[user.id] = user;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    usersChanged(users) {
      this.$emit(
        "usersChanged",
        users.map((item) => {
          return { id: item, type: "users" };
        })
      );
    },
  },

  watch: {
    users(users) {
      this.usersModel = users.map((item) => item.id);
      this.getUsers(null, users);
    },
    filterOrganization(filterOrganization) {
      this.getUsers();
    },
    filterOrganizationNull(filterOrganization) {
      this.getUsers();
    },
    filterReseller(filterReseller) {
      this.getUsers();
    },
    filterResellerNull(filterReseller) {
      this.getUsers();
    },
  },
};
</script>
