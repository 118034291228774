export default {
  type: "folders",
  name: null,
  excerpt: '',
  relationshipNames: ["folders", "organization", "tags", "allowedLocations", "roles", "users", "parent"],
  parent: {
    type: "folders",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  tags: [],
  allowedLocations: [],
  roles: [],
  users: [],
};
